<template>
  <div>
    <tabs v-model="activeTab" class="main-content" @on-search="updateSearch">
      <tab name="active" label="團購中" role="tab">
        <GroupList
          :type="2"
          :apiFunc="api"
          :statusFilter="1"
          :is-active="`active` === activeTab"
          :search-string="search"
        ></GroupList>
      </tab>
      <tab name="valid" label="已成團" role="tab">
        <GroupList
          :type="2"
          :apiFunc="api"
          :statusFilter="[2, 3]"
          :is-active="`valid` === activeTab"
          :tabName="`valid`"
          :search-string="search"
          :totalUnpaidAmount="totalUnpaidAmount"
          :per_page="`0`"
          :isLoadAll="true"
        ></GroupList>
      </tab>
      <tab name="history" label="跟過的團" role="tab">
        <GroupList
          :type="2"
          :apiFunc="api"
          :statusFilter="[4, 5]"
          :is-active="`history` === activeTab"
          :search-string="search"
        ></GroupList>
      </tab>
      <tab name="remark" label="未跟過的" role="tab">
        <GroupList
          :type="2"
          :apiFunc="favoriteApi"
          :statusFilter="-999"
          :is-active="`remark` === activeTab"
          :search-string="search"
        ></GroupList>
      </tab>

      <template v-slot:toolBar>
        <SearchBlock
          class="full"
          placeholder="請輸入開團名稱"
          @on-search="updateSearch"
        ></SearchBlock>
      </template>
    </tabs>
  </div>
</template>

<script>
import {
  getMyFollowGroups,
  fetchFavoritedGroups,
  getTotalUnpaidAmount
} from '@/api/member';
import GroupList from '@/components/GroupList';
import tabs from '@/components/GroupTabs';
import tab from '@/components/GroupTabs/tab';
import SearchBlock from '@/components/ListSearchBlock';

export default {
  components: {
    tabs,
    tab,
    GroupList,
    SearchBlock
  },
  data() {
    return {
      activeTab: 'active',
      api: getMyFollowGroups,
      favoriteApi: fetchFavoritedGroups,
      search: null,
      totalUnpaidAmount: 0
    };
  },
  watch: {
    activeTab(newVal) {
      this.$route.query.tab = newVal;
    }
  },
  methods: {
    updateSearch(search) {
      this.search = search;
    }
  },
  created() {
    if (this.$route?.query?.tab) {
      this.activeTab = this.$route.query.tab;
    }

    getTotalUnpaidAmount().then(res => {
      this.totalUnpaidAmount = res.totalUnpaidAmount;
    });
  }
};
</script>
